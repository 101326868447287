import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import PublicCheckInAttemptForm from '../components/PublicCheckInAttemptForm';
import PublicCheckInConfirmationForm from '../components/PublicCheckInConfirmationForm';

export interface CheckInAttemptFormValues {
    applicantLastName: string;
    applicantDateOfBirth: string;
    applicantLastFourSSN: string;
    vendorCode: string;
}

export interface CheckInConfirmationFormValues {
    applicantConfirmationCode: string;
}

interface Props {
    logger: any;
}

function PublicCheckIn({ logger }: Props) {
    const [searching, setSearching] = useState(false);
    const [checkedIn, setCheckedIn] = useState(false);
    const [error, setError] = useState(false);
    const [successfulCheckInAttempt, setSuccessfulCheckInAttempt] = useState(false);
    const [successfulCheckInConfirmation, setSuccessfulCheckInConfirmation] = useState(false);

    const applicantCheckInCodeId = useRef();
    const applicantCheckInAttemptId = useRef();
    const providerFaxNumbers = useRef([]);







    interface ProviderFaxObject {
        key: string;
        value: string
    }

    const attemptCheckIn = async (formValues: CheckInAttemptFormValues) => {
        try {
            setError(false);

            let {
                applicantLastName,
                applicantDateOfBirth,
                applicantLastFourSSN,
                vendorCode
            } = formValues;

            setSearching(true);

            const checkInAttemptAxiosConfig: AxiosRequestConfig = {
                url: `${process.env.REACT_APP_MAIN_PLATFORM_API_URL || 'https://exam-check-in-api.azurewebsites.net'}/api/v0/check-in-attempt`,
                method: 'post',
                data: {
                    vendorCode,
                    applicantLastName,
                    applicantDateOfBirth,
                    applicantLastFour: applicantLastFourSSN
                },
                headers: {}
            };

            const checkInAttemptResponse: AxiosResponse = await axios(checkInAttemptAxiosConfig);

            setSearching(false);

            logger.info('Successful check-in attempt', checkInAttemptResponse.data);

            applicantCheckInCodeId.current = checkInAttemptResponse.data.applicantCheckInCodeId;
            applicantCheckInAttemptId.current = checkInAttemptResponse.data.id;

            setSuccessfulCheckInAttempt(true);

        } catch (error: any) {
            setSearching(false);
            setError(true);
            logger.error(error);
        }
    }

    const confirmCheckIn = async (formValues: CheckInConfirmationFormValues) => {
        try {
            setError(false);

            let { applicantConfirmationCode } = formValues

            setSearching(true);

            const checkInConfirmationAxiosConfig: AxiosRequestConfig = {
                url: `${process.env.REACT_APP_MAIN_PLATFORM_API_URL || 'https://exam-check-in-api.azurewebsites.net'}/api/v0/check-in-confirmation`,
                method: 'post',
                data: {
                    applicantCheckInCodeId: applicantCheckInCodeId.current,
                    applicantCheckInAttemptId: applicantCheckInAttemptId.current,
                    checkInCode: applicantConfirmationCode
                },
                headers: {}
            };

            const checkInConfirmationResponse: AxiosResponse = await axios(checkInConfirmationAxiosConfig);

            setSearching(false);

            logger.info('Successful check-in confirmation', checkInConfirmationResponse.data);

            providerFaxNumbers.current = checkInConfirmationResponse.data.provider.faxNumbers;

            setSuccessfulCheckInConfirmation(true);
            setCheckedIn(true);

        } catch (error: any) {
            setSearching(false);
            setError(true);
            console.error(error);
        }
    }

    return (
        <div style={{ margin: '0px 30%' }}>
            <h1 className="display-2 mt-4" style={{ textAlign: 'center' }}>Exam Check-In</h1>
            <p className="lead" style={{ textAlign: 'center' }}>Applicants must be checked in to be fully authorized for exams</p>

            <Row>
                <Col className="my-2">

                    {error && <div className="p-3 my-2" style={{ backgroundColor: '#F8E1E0', color: '#d9534f !important' }}>An error has occurred. Ensure all fields are correct and try again.</div>}

                    {searching && <div className="my-2" style={{ textAlign: 'center' }}>...searching</div>}

                    {!successfulCheckInAttempt &&
                        <Row>
                            <Col lg={8} style={{ margin: 'auto' }}>
                                <PublicCheckInAttemptForm attemptCheckIn={attemptCheckIn} />
                            </Col>
                        </Row>
                    }

                    {successfulCheckInAttempt && !successfulCheckInConfirmation && !checkedIn &&
                        <Row>
                            <Col lg={8} style={{ margin: 'auto' }}>
                                <PublicCheckInConfirmationForm confirmCheckIn={confirmCheckIn} />
                            </Col>
                        </Row>
                    }

                    {checkedIn &&
                        <>
                            <Row>
                                <Col lg={8} style={{ margin: 'auto' }}>
                                    <h5 className="mb-3 mb-4 text-success"><i className="bi bi-calendar-check"></i> Applicant has been checked in </h5>
                                    <h6 className="lead">Fax exam authorization to:</h6>
                                    <ul style={{ listStyleType: "none" }} className="ps-0">
                                        {providerFaxNumbers.current.map((providerFaxObject: ProviderFaxObject) => (
                                            <li key={providerFaxObject.key}>
                                                <div className="card p-3 shadow-sm mb-2">
                                                    {providerFaxObject.value}
                                                    <button className="btn btn-sm shadow-sm btn-primary mt-2" style={{ width: "20%" }}>Send Fax</button>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                        </>
                    }

                </Col>
            </Row>
        </div>
    );
};

export default PublicCheckIn;
