import { withFormik, FormikProps } from "formik";
import * as Yup from "yup";
import { Col, Form, Row } from 'react-bootstrap';

interface FormValues {
    applicantLastName: string;
    applicantDateOfBirth: string;
    applicantLastFourSSN: string;
    vendorCode: string;
}

interface Props {
    attemptCheckIn: (values: FormValues) => void;
}

const PublicCheckInAttemptForm = ({ attemptCheckIn }: Props) => {
    interface MyFormProps {
        initial_applicantLastName?: string;
        initial_applicantDateOfBirth?: string;
        initial_applicantLastFourSSN?: string;
        initial_vendorCode?: string;
    }

    const lastFourSSNRegex = /^\d{4}(?:[-\s]\d{4})?$/;
    const vendorCodeRegex = /^[a-zA-Z0-9]{6}(?:[-\s][a-zA-Z0-9]{6})?$/;

    const schema = Yup.object().shape({
        applicantLastName: Yup.string().required("Applicant last name required"),
        applicantDateOfBirth: Yup.date().required("Applicant date of birth required"),
        applicantLastFourSSN: Yup.string().min(4, 'Must be 4 digits').max(4, 'Must be 4 digits').required("Applicant last 4 of SSN required").matches(lastFourSSNRegex, { message: "Must be all numbers" }),
        vendorCode: Yup.string().required('Vendor code is required').matches(vendorCodeRegex, { message: "Must be an alphanumeric code" }),
    });

    // =========================================================================

    function submitPublicCheckInAttemptForm(values: FormValues) {
        // TODO: get the real vendorId and real caseId (with url params?) for this form
        // console.log("values are: ", values)

        attemptCheckIn(values);
    }

    const InnerForm = (props: FormikProps<FormValues>) => {
        const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
        } = props;

        return (
            <div>
                <form onSubmit={handleSubmit}>
                    <label>Applicant Last Name</label>
                    <Form.Control required className="mb-2" name="applicantLastName" value={values.applicantLastName} onChange={handleChange} onBlur={handleBlur} />
                    {errors.applicantLastName && touched.applicantLastName &&
                        <div className="text-danger mb-2 mt-0">{errors.applicantLastName}</div>
                    }

                    <label>Applicant Date of Birth</label>
                    <Form.Control required className="mb-2" type="date" name="applicantDateOfBirth" value={values.applicantDateOfBirth} onChange={handleChange} onBlur={handleBlur} />
                    {errors.applicantDateOfBirth && touched.applicantDateOfBirth &&
                        <div className="text-danger mb-2 mt-0">{errors.applicantDateOfBirth}</div>
                    }

                    <label>Applicant Last 4 Digits of SSN</label>
                    <Row>
                        <Col lg={5}>
                            <Form.Control required className="mb-2" name="applicantLastFourSSN" value={values.applicantLastFourSSN} onChange={handleChange} onBlur={handleBlur} maxLength={4} />
                        </Col>
                        {errors.applicantLastFourSSN && touched.applicantLastFourSSN &&
                            <div className="text-danger mb-2 mt-0">{errors.applicantLastFourSSN}</div>
                        }
                    </Row>

                    <label>Vendor Code</label>
                    <Form.Control required className="mb-2" name="vendorCode" value={values.vendorCode} onChange={handleChange} onBlur={handleBlur} />
                    {errors.vendorCode && touched.vendorCode &&
                        <div className="text-danger mb-2 mt-0">{errors.vendorCode}</div>
                    }

                    <button className="btn btn-primary btn-sm mt-2"
                        type="submit"
                        disabled={
                            isSubmitting ||
                            !!(errors.applicantLastName && touched.applicantLastName) ||
                            !!(errors.applicantDateOfBirth && touched.applicantDateOfBirth) ||
                            !!(errors.applicantLastFourSSN && touched.applicantLastFourSSN) ||
                            !!(errors.vendorCode && touched.vendorCode)
                        }
                    >
                        Check in
                    </button>
                </form>
            </div >
        );
    };


    const PublicCheckInAttemptForm = withFormik<MyFormProps, FormValues>({
        mapPropsToValues: props => ({
            applicantLastName: props.initial_applicantLastName || "",
            applicantDateOfBirth: props.initial_applicantDateOfBirth || "",
            applicantLastFourSSN: props.initial_applicantLastFourSSN || "",
            vendorCode: props.initial_vendorCode || "",
        }),

        validationSchema: schema,

        handleSubmit(
            {
                applicantLastName,
                applicantDateOfBirth,
                applicantLastFourSSN,
                vendorCode
            }: FormValues,
            // { props, setSubmitting, setErrors }
        ) {
            let formVals = {
                applicantLastName,
                applicantDateOfBirth,
                applicantLastFourSSN,
                vendorCode,
            }
            submitPublicCheckInAttemptForm(formVals)
        }
    })(InnerForm);

    return (
        <>
            <PublicCheckInAttemptForm />
        </>
    );
}

export default PublicCheckInAttemptForm;
