import { withFormik, FormikProps } from "formik";
import * as Yup from "yup";
import { Form } from 'react-bootstrap';

interface FormValues {
    applicantConfirmationCode: string;
}

interface Props {
    confirmCheckIn: (values: FormValues) => void;
};

const PublicCheckInConfirmationForm = ({confirmCheckIn}: Props) => {
    interface MyFormProps {
        initial_applicantConfirmationCode?: string;
    }

    const applicantConfirmationCodeRegex = /^[a-zA-Z0-9]{6}(?:[-\s][a-zA-Z0-9]{6})?$/;

    const schema = Yup.object().shape({
        applicantConfirmationCode: Yup.string().required("Applicant confirmation code required").matches(applicantConfirmationCodeRegex, { message: "Must be an alphanumeric code" })
    });

    // =========================================================================

    function submitPublicCheckInConfirmationForm(values: FormValues) {
        confirmCheckIn(values);
    }

    const InnerForm = (props: FormikProps<FormValues>) => {
        const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
        } = props;

        return (
            <div>
                <form onSubmit={handleSubmit}>
                    <label>Applicant Confirmation Code</label>
                    <Form.Control required className="mb-2" name="applicantConfirmationCode" value={values.applicantConfirmationCode} onChange={handleChange} onBlur={handleBlur} />
                    {errors.applicantConfirmationCode && touched.applicantConfirmationCode &&
                        <div className="text-danger mb-2 mt-0">{errors.applicantConfirmationCode}</div>
                    }

                    <button className="btn btn-primary btn-sm mt-2"
                        type="submit"
                        disabled={isSubmitting || !!(errors.applicantConfirmationCode && touched.applicantConfirmationCode)}
                    >
                        Check in
                    </button>
                </form>
            </div >
        );
    };


    const PublicCheckInConfirmationForm = withFormik<MyFormProps, FormValues>({
        mapPropsToValues: props => ({
            applicantConfirmationCode: props.initial_applicantConfirmationCode || ""
        }),

        validationSchema: schema,

        handleSubmit({applicantConfirmationCode}: FormValues) {
            let formVals = {applicantConfirmationCode}
            submitPublicCheckInConfirmationForm(formVals)
        }
    })(InnerForm);

    return (
        <>
            <PublicCheckInConfirmationForm />
        </>
    );
};

export default PublicCheckInConfirmationForm;