import { Routes, Route } from 'react-router-dom';
import PrivateCheckIn from './pages/PrivateCheckIn';
import PublicCheckIn from './pages/PublicCheckIn';
import logger from './utils/logger';

export interface CheckInAttemptFormValues {
    applicantLastName: string;
    applicantDateOfBirth: string;
    applicantLastFourSSN: string;
    vendorCode: string;
}

export interface CheckInConfirmationFormValues {
    applicantConfirmationCode: string;
}

export interface Logger {
    level: string;
}

function App() {



    return (
        <div>
            <Routes>
                <Route path="/" element={<PublicCheckIn logger={logger} />} />
                {/* these parameters are base 64 encoded: verificationCode, applicantId, caseExamId, vendorCode, userNamePerformingCheckIn */}
                <Route path="/private/:verificationCode/:applicantId/:caseExamId/:vendorCode/:userNamePerformingCheckIn" element={<PrivateCheckIn logger={logger} />} />
                <Route path="*" element={<h1>Not found</h1>} />
            </Routes>
        </div>
    );
};

export default App;
