import { withFormik, FormikProps } from "formik";
import * as Yup from "yup";
import { Form } from 'react-bootstrap';

export interface PrivateCheckInFormValues {
    code: string;
}

interface Props {
    attemptCheckIn: (values: PrivateCheckInFormValues) => void;
    submitting: boolean;
}

const PrivateCheckInForm = ({ attemptCheckIn, submitting }: Props) => {
    interface MyFormProps {
        initial_code?: string;
    }

    const schema = Yup.object().shape({
        code: Yup.string().min(6, 'Must be 6 characters').max(6, 'Must be 6 characters').required("Check in code is required")
    });

    // =========================================================================

    function submitPrivateCheckInForm(values: PrivateCheckInFormValues) {
        // TODO: get the real vendorId and real caseId (with url params?) for this form
        // console.log("values are: ", values)

        attemptCheckIn(values);
    }

    const InnerForm = (props: FormikProps<PrivateCheckInFormValues>) => {
        const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
        } = props;

        return (
            <div>
                <form onSubmit={handleSubmit}>
                    <label>Check-in Code</label>
                    <Form.Control required className="mb-2" name="code" value={values.code} onChange={handleChange} onBlur={handleBlur} disabled={submitting} autoFocus/>
                    {errors.code && touched.code &&
                        <div className="text-danger mb-2 mt-0">{errors.code}</div>
                    }

                    <button className="btn btn-primary btn-sm mt-2"
                        type="submit"
                        disabled={
                            submitting ||
                            !!(errors.code && touched.code)
                        }
                    >
                        Check in
                    </button>
                </form>
            </div >
        );
    };


    const PrivateCheckInForm = withFormik<MyFormProps, PrivateCheckInFormValues>({
        mapPropsToValues: props => ({
            code: props.initial_code || "",
        }),

        validationSchema: schema,

        handleSubmit(
            {
                code
            }: PrivateCheckInFormValues,
            // { props, setSubmitting, setErrors }
        ) {
            let formVals = {
                code
            }
            submitPrivateCheckInForm(formVals)
        }
    })(InnerForm);

    return (
        <>
            <PrivateCheckInForm />
        </>
    );
}

export default PrivateCheckInForm;
