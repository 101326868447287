import axios from 'axios';

const url = process.env.REACT_APP_MAIN_PLATFORM_API_URL;

export interface ConfirmCheckInValues {
    applicantCheckInCodeId: number;
    applicantCheckInAttemptId: number;
    checkInCode: string;
}

export interface CheckInValues {
    applicantLastName: string;
    applicantDateOfBirth: string;
    applicantLastFour: string;
    vendorCode: string;
}

export interface VerifyGuidReqBody {
    guid: string;
    idValue: string;
}

const API = {
    retrieveApplicantData: function (applicantId: number) {
        return axios.get(`${url}/api/v0/basic/applicant/${applicantId}/flat/fullview`);
    },
    attemptPrivateCheckIn: function (checkInValues: CheckInValues) {
        return axios.post(`${url}/api/v0/check-in-attempt-private`, checkInValues);
    },
    confirmCheckIn: function (checkInConfirmationValues: ConfirmCheckInValues) {
        return axios.post(`${url}/api/v0/check-in-confirmation`, checkInConfirmationValues);
    },
    verifyGuid: function (reqBody: VerifyGuidReqBody) {
        return axios.post(`${url}/api/v0/verify-guid`, reqBody)
    },
}

export default API;