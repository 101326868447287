import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Spinner } from 'react-bootstrap';
// import { CheckInAttemptFormValues } from '../App';
import API, { CheckInValues } from '../utils/API';
import PrivateCheckInForm, { PrivateCheckInFormValues } from '../components/PrivateCheckInForm';

interface Props {
    logger: any;
}

export default function PrivateCheckIn({ logger }: Props) {

    const { verificationCode, applicantId, caseExamId, vendorCode, userNamePerformingCheckIn } = useParams();


    // the verificationCode stays as a base64 string. The rest (applicantId, caseExamId, vendorCode, and userNamePerformingCheckIn are all converted from base64 into plain text strings)
    const applicantIdDecoded = (Buffer.from(applicantId as string, 'base64')).toString();
    const caseExamIdDecoded = (Buffer.from(caseExamId as string, 'base64')).toString();
    const vendorCodeDecoded = (Buffer.from(vendorCode as string, 'base64')).toString();
    const userNamePerformingCheckInDecoded = (Buffer.from(userNamePerformingCheckIn as string, 'base64')).toString();

    


    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState<any>();
    const [submitting, setSubmitting] = useState(false);

    const [applicantCheckInCodeId, setApplicantCheckInCodeId] = useState(0);
    const [attemptId, setAttemptId] = useState(0);

    const [showSuccessfulPrivateCheckIn, setShowSuccessfulPrivateCheckin] = useState(false);
    const [showErrorPrivateCheckIn, setShowErrorPrivateCheckIn] = useState(false);



    useEffect(() => {
        // Log url parameters
        logger.info("verificationCode: ", verificationCode, "\napplicantId: ", applicantId, "\ncaseExamId: ", caseExamId, "\nvendorCode: ", vendorCode, "\nuserNamePerformingCheckIn", userNamePerformingCheckIn);
        // Log url parameters decoded
        logger.info("applicantIdDecoded: ", applicantIdDecoded, "\ncaseExamIdDecoded: ", caseExamIdDecoded, "\nvendorCodeDecoded: ", vendorCodeDecoded, "\nuserNamePerformingCheckInDecoded: ", userNamePerformingCheckInDecoded);

        // Url params must exist and be valid for page to load
        const verifyUrlParams = () => {
            if (!verificationCode || !applicantId || !caseExamId || !vendorCode || !userNamePerformingCheckIn) {
                return false;
            } else {
                return true;
            }
        }
        const areUrlParamsValid = verifyUrlParams();
        logger.info("areUrlParamsValid: ", areUrlParamsValid);

        const checkVerificationCode = async () => {
            logger.info("checking verification code...");
            logger.info("verificationCode (guid): ", verificationCode, "\nidValue (caseExamId): ", caseExamIdDecoded);
            if (verificationCode) {

                try {
                    let data = {
                        guid: verificationCode,
                        idValue: caseExamIdDecoded // note that caseId comes from caseExams[x].id, not from case.id
                    };
                    logger.info("verifyGuid req.body: ", data);
                    let verificationResponse = await API.verifyGuid(data);
                    logger.info("verification results: ", verificationResponse);
                    return true;

                } catch (err) {
                    logger.error("verification error: ", err);
                    setError(true);
                    setErrorMsg('invalid verification code');
                    return false;
                }
            } else {
                logger.info('no verification code provided. Could not check verification code');
            }

        }

        // Run this function on page load to check in the applicant privately
        const runPrivateCheckInOnPageLoad = async (checkInValues: CheckInValues) => {
            logger.info('attempting private checking in on page load');
            // send check in data to the backend, which we should have available to us upon page load
            setLoading(true);
            try {
                const checkInAttemptResponse = await API.attemptPrivateCheckIn(checkInValues);
                logger.info('checkInAttemptResponse:', checkInAttemptResponse.data);

                const applicantCheckInCodeId = checkInAttemptResponse.data.applicantCheckInCodeId;
                const applicantCheckInAttemptId = checkInAttemptResponse.data.id;
                logger.info('applicantCheckInCodeId: ', applicantCheckInCodeId);
                logger.info('applicantCheckInAttemptId: ', applicantCheckInAttemptId);
                setApplicantCheckInCodeId(applicantCheckInCodeId);
                setAttemptId(applicantCheckInAttemptId);

                setError(false);
                setLoading(false);
                setLoaded(true);

            } catch (err) {
                logger.error(err);
                logger.info('private checking in on page load attempt failed');
                setError(true);
                setErrorMsg(err);
                setLoading(false);
                setLoaded(true);
            }
        }



        if (areUrlParamsValid === false) {
            setError(true);
        } else if (areUrlParamsValid === true) {
            // 1) load applicantData
            if (applicantId && vendorCode) {
                const applicantIdAsNumber: number = parseInt(applicantIdDecoded);
                logger.info(`retrieving applicant with id of ${applicantIdAsNumber}...`);
                API.retrieveApplicantData(applicantIdAsNumber)
                    .then(res => {
                        logger.info("applicantdata retrieved: ", res.data);

                        if (res.data && res.data.length > 0 && res.data[0].applicant && res.data[0].providers && res.data[0].caseExams && res.data[0].caseExams.length > 0) {
                            const data = res.data[0].applicant;
                            if (data.lastName && data.dateOfBirth && data.ssnLast4) {
                                const applicantLastName = res.data[0].applicant.lastName;
                                const applicantDateOfBirth = res.data[0].applicant.dateOfBirth;
                                const applicantLastFourSSN = res.data[0].applicant.ssnLast4;


                                const checkInData = {
                                    applicantLastName,
                                    applicantDateOfBirth,
                                    applicantLastFour: applicantLastFourSSN,
                                    vendorCode: vendorCodeDecoded
                                }
                                logger.info('checkInData to be sent to API: ', checkInData);
                                // 2) check the verification code
                                checkVerificationCode().then(res => {
                                    const isVerified = res;
                                    logger.info("is verification code valid: ", isVerified);
                                    if (isVerified === true) {
                                        // 3) run private check in
                                        runPrivateCheckInOnPageLoad(checkInData);
                                        setError(false);
                                    } else {
                                        setError(true);
                                        setErrorMsg('invalid verification code');
                                    }
                                })

                            } else {
                                setError(true);
                                setErrorMsg('invalid applicant data');
                            }
                        } else {
                            setError(true);
                            setErrorMsg('invalid applicant data');
                        }
                    })
                    .catch(err => {
                        logger.info('failed to retrieve applicant');
                        logger.error(err);
                        setError(true);
                        setErrorMsg(err);
                    })
            } else {
                setError(true);
            }
        }

    }, [verificationCode, applicantId, caseExamId, vendorCode, userNamePerformingCheckIn, logger, applicantIdDecoded, caseExamIdDecoded, vendorCodeDecoded, userNamePerformingCheckInDecoded]);



    // Run when user submits check in code
    const attemptPrivateCheckIn = async (values: PrivateCheckInFormValues) => {
        logger.info('attempting private check in');
        const code = values.code;
        setSubmitting(true);
        try {
            const confirmCheckInData = {
                applicantCheckInCodeId: applicantCheckInCodeId,
                applicantCheckInAttemptId: attemptId,
                checkInCode: code
            };

            let apiResponse = await API.confirmCheckIn(confirmCheckInData);
            logger.info("confirm checkIn apiResponse: ", apiResponse);
            setShowSuccessfulPrivateCheckin(true);
            setShowErrorPrivateCheckIn(false);
            setSubmitting(false);
        } catch (err) {
            logger.error(err);
            setShowSuccessfulPrivateCheckin(false);
            setShowErrorPrivateCheckIn(true);
            setSubmitting(false);
            setErrorMsg(err);
        }

    }


    return (
        <div style={{ margin: '0px 30%' }}>
            <h1 className="display-2 mt-4" style={{ textAlign: 'center' }}>Exam Check-In</h1>
            <p className="lead" style={{ textAlign: 'center' }}>Applicants must be checked in to be fully authorized for exams</p>

            <Row>
                <Col>
                    {error &&
                        <>
                            <div className="bg-soft-danger p-3">An error has occurred {process.env.ENVIRONMENT === 'development' && <div><pre>{JSON.stringify(errorMsg, null, 4)}</pre></div>}</div>
                        </>
                    }
                </Col>
            </Row>

            <Row>
                <Col lg={8} style={{ margin: 'auto' }}>

                    {loading && <div><Spinner animation="border" variant="primary" size="sm" /> Loading...</div>}
                </Col>
            </Row>

            {!error && !loading && loaded &&
                <div>
                    <Row>
                        <Col lg={8} style={{ margin: 'auto' }}>

                            {submitting && <div>  <Spinner animation="border" variant="primary" size="sm" /> Submitting...</div>}
                            {!submitting && showSuccessfulPrivateCheckIn &&
                                <Row>
                                    <Col lg={8} style={{ margin: 'auto' }}>
                                        <h5 className="mb-3 mb-4 text-success"><i className="bi bi-calendar-check"></i> Applicant has been checked in </h5>
                                    </Col>
                                </Row>
                            }
                            {!submitting && showErrorPrivateCheckIn &&
                                <div className="p-3 my-2" style={{ backgroundColor: '#F8E1E0', color: '#d9534f !important' }}>An error has occurred. Ensure all fields are correct and try again.</div>
                            }

                        </Col>
                    </Row>
                    {!showSuccessfulPrivateCheckIn &&
                        <Row>
                            <Col lg={8} style={{ margin: 'auto' }}>
                                <PrivateCheckInForm attemptCheckIn={attemptPrivateCheckIn} submitting={submitting} />
                            </Col>
                        </Row>
                    }

                </div>
            }

        </div>
    )
}